import styled, { css } from 'styled-components';
import BaseArrowLink from 'Elements/Link/Arrow';

export const Wrapper = styled.div<{ $hasCourseSearch: boolean }>`
    ${({ theme, $hasCourseSearch }) => css`
        background-color: ${theme.colors.tertiary.hex};
        height: auto;
        align-content: center;

        @media ${theme.breakpoints.medium.media} {
            padding-top: ${$hasCourseSearch ? 0 : '0.5rem'};
        }

        @media ${theme.breakpoints.large.media} {
            padding: ${$hasCourseSearch ? '1.75rem 0' : '1.625rem 0'};
        }
    `}
`;

export const SearchContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        position: relative;
        margin: 1.5rem 0 1rem;
        justify-content: center;

        @media ${theme.breakpoints.medium.media} {
            margin-bottom: 2rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin: 0;
        }
    `}
`;

export const ButtonsWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        padding: 0.5rem 0 0;

        @media ${theme.breakpoints.medium.media} {
            flex-direction: column;
            padding: 0;
        }
    `}
`;

export const Heading = styled.h2<{ $hasCourseSearch: boolean }>`
    ${({ theme, $hasCourseSearch }) => css`
        font-family: ${theme.fonts.secondary};
        font-size: 1.5rem;
        font-family: ${theme.fonts.secondary};
        font-weight: 300;
        color: ${theme.colors.primaryContrast.hex};
        margin: 0;
        line-height: 1.75rem;

        @media ${theme.breakpoints.medium.media} {
            text-align: center;
            font-size: 2rem;
            margin-bottom: ${$hasCourseSearch ? 0 : '1.5rem'};
            align-self: center;
            line-height: 2.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            text-align: left;
            align-self: flex-start;
            margin-bottom: ${$hasCourseSearch ? '0.5rem' : 0};
        }
    `}
`;

export const Links = styled.ul`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        list-style: none;
        width: 100%;
        text-align: right;
        margin: 0;
        padding: 0;

        @media ${theme.breakpoints.medium.media} {
            align-items: center;
            flex-direction: row;
        }

        @media ${theme.breakpoints.large.media} {
            align-items: left;
            justify-content: space-between;
        }
    `}
`;

export const ArrowLink = styled(BaseArrowLink)`
    ${({ theme }) => {
        const colors = [
            theme.colors.accents[4].hex,
            theme.colors.accents[1].hex,
            theme.colors.accents[6].hex
        ];
        return colors.map(
            (color, index) => css`
                li:nth-child(${index + 1}) & {
                    color: ${color};
                    margin-bottom: 0.75rem;

                    @media ${theme.breakpoints.large.media} {
                        margin-bottom: 0;
                    }

                    svg {
                        color: ${theme.colors.tertiary.hex};
                    }
                }
            `
        );
    }}
`;
