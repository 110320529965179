import React, { useState } from 'react';
import { useSpring } from '@react-spring/web';
import NextImage, { StaticImageData } from 'next/image';

import type { TableProps } from 'Components/Table';

import {
    Container,
    Button,
    ImageWrapper,
    IconWrapper,
    StyledLink,
    PlayIcon,
    ResponsiveVideoWrapper,
    Iframe,
    FigureCaption
} from './Video.styled';

export interface Props {
    id?: string;
    title?: string; // TODO: will need to be required once all videos swapped to transcriptEmbed
    video: {
        src: string;
        title?: string;
    };
    image: {
        src: StaticImageData | string;
        alt?: string;
        priority?: boolean;
    };
    caption?: string;
    transcript?: TableProps;
    transcriptUrl?: string; //TODO: will need to be changed to required once all videos swapped
}

const Video: React.FC<Props> = props => {
    const { id, image, video, title, caption, transcript, transcriptUrl } = props;
    const [isHovering, setIsHovering] = useState(false);
    const [isVideoPlaying, setVideoIsPlaying] = useState(false);

    const iconButtonSpring = useSpring({
        transform: isHovering ? 'scale(1.2)' : 'scale(1)',
        config: {
            mass: 2,
            tension: 200,
            friction: 20
        }
    });

    return (
        <Container id={id} className="c-video">
            {isVideoPlaying ? (
                <ResponsiveVideoWrapper>
                    <Iframe
                        src={video.src + '?autoplay=1'}
                        title={`YouTube video player: ${video.title}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </ResponsiveVideoWrapper>
            ) : (
                <Button
                    tabIndex={0}
                    onMouseOver={() => setIsHovering(true)}
                    onMouseOut={() => setIsHovering(false)}
                    data-hover={isHovering}
                    aria-label={`Play video: ${title}`}
                    onClick={() => setVideoIsPlaying(!isVideoPlaying)}
                    className="button c-video-play"
                >
                    <ImageWrapper>
                        <NextImage
                            src={image.src}
                            alt={image.alt || ''}
                            fill
                            style={{ objectFit: 'cover' }}
                            priority={image.priority}
                        />
                    </ImageWrapper>
                    <IconWrapper style={iconButtonSpring}>
                        <PlayIcon fill="currentColor" />
                    </IconWrapper>
                </Button>
            )}
            {caption && <FigureCaption>{caption}</FigureCaption>}
            {transcript && transcriptUrl && transcript.tableRows.length >= 1 && (
                <StyledLink
                    href={transcriptUrl}
                    openInNewTab={true}
                    ariaLabel={`Open transcript video page for ${title}`}
                >
                    Video transcript, with a description of visuals available.
                </StyledLink>
            )}
        </Container>
    );
};

export default Video;
