import React from 'react';

import Grid, { Col } from 'Components/Grid';
import { Props as ArrowLinkProps } from 'Elements/Link/Arrow';
import type { Props as SearchCourseProps } from 'Components/Search/Course';
import SearchCourse from 'Components/Search/Course';

import {
    Wrapper,
    ButtonsWrapper,
    Heading,
    Links,
    ArrowLink,
    SearchContainer
} from './QuickLinks.styled';

interface QuickLinkButtonProps extends ArrowLinkProps {
    id: string;
}
export interface Props extends React.HTMLAttributes<HTMLElement> {
    heading: string;
    links: QuickLinkButtonProps[];
    useCourseSearch: boolean;
    searchCourse?: SearchCourseProps;
}

const QuickLinks: React.FC<Props> = props => {
    const { heading, links, searchCourse, useCourseSearch, ...rest } = props;

    const hasCourseSearch: boolean = useCourseSearch && searchCourse ? true : false;

    return (
        <Wrapper {...rest} $hasCourseSearch={hasCourseSearch}>
            <Grid>
                <Col s={hasCourseSearch ? 12 : 6} m={[3, 11]} l={[1, 6]} align="center">
                    {useCourseSearch && searchCourse ? (
                        <SearchContainer>
                            <SearchCourse {...searchCourse} heightType="tall" />
                        </SearchContainer>
                    ) : (
                        <Heading $hasCourseSearch={hasCourseSearch}>{heading}</Heading>
                    )}
                </Col>
                <Col s={hasCourseSearch ? 12 : 6} m={12} l={[7, 13]}>
                    <ButtonsWrapper>
                        {useCourseSearch && searchCourse && (
                            <Heading $hasCourseSearch={hasCourseSearch}>{heading}</Heading>
                        )}
                        <Links>
                            {links.map(({ id, ...props }) => (
                                <li key={id}>
                                    <ArrowLink {...props} />
                                </li>
                            ))}
                        </Links>
                    </ButtonsWrapper>
                </Col>
            </Grid>
        </Wrapper>
    );
};

export default QuickLinks;
