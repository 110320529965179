import React, { useEffect } from 'react';
import { StaticImageData } from 'next/image';

import { ButtonProps } from 'Campaigns/Clearing/Button';

import { Container, StyledImage, PreHeading, Heading, StyledButton } from './Card.styled';

export interface Props {
    image: {
        alt?: string;
        src: StaticImageData | string;
    };
    preHeading: string;
    heading: string;
    button: ButtonProps;
}

const Card: React.FC<Props> = props => {
    const { image, preHeading, heading, button } = props;

    useEffect(() => {
        const link = document.createElement('link');

        link.rel = 'stylesheet';
        link.href = 'https://use.typekit.net/mul1xwk.css';

        document.body.appendChild(link);
    }, []);

    return (
        <Container>
            <StyledImage src={image.src} alt={image.alt || ''} width={288} height={216} />
            <div>
                <PreHeading>{preHeading}</PreHeading>
                <Heading>{heading}</Heading>
            </div>
            <StyledButton {...button} />
        </Container>
    );
};

export default Card;
