import React from 'react';

import { Wrapper } from './Pill.styled';

export interface Props {
    children: React.ReactNode | string;
    backgroundColor?: React.CSSProperties['color'];
    textColor?: React.CSSProperties['color'];
    showBorder?: boolean;
}

const Pill: React.FC<Props> = ({ children, backgroundColor, textColor, showBorder = false }) => {
    return (
        <Wrapper
            style={
                {
                    '--bg-color': backgroundColor,
                    '--text-color': textColor
                } as React.CSSProperties
            }
            $showBorder={showBorder}
        >
            {children}
        </Wrapper>
    );
};

export default Pill;
