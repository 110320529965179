import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const KeyStatsStyled = styled.div`
    text-align: center;
`;

export const StatsContainer = styled.div`
    @media ${({ theme }) => theme.breakpoints.medium.media} {
        display: flex;
        justify-content: space-around;
    }
`;

export const KeyStatsContainer = styled.ul`
    ${({ theme }) => css`
        padding: 0;
        list-style-type: none;

        & + & {
            &:before {
                content: '';
                border-top: 0.1rem solid ${theme.colors.secondary.tints[1].hex};
                width: 50%;
                display: block;
                margin: 0 auto;
            }
        }

        @media ${theme.breakpoints.medium.media} {
            & + & {
                &:before {
                    content: none;
                }
            }
        }
    `}
`;

export const Heading = styled.h3`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        font-weight: 300;
        font-size: 1.75rem;
        margin-bottom: 1rem;

        @media ${theme.breakpoints.large.media} {
            font-size: 2.5rem;
        }
    `}
`;

export const CtaContainer = styled(animated.div)`
    margin-top: 1.5rem;
`;
