import React from 'react';
import type CSS from 'csstype';
import NextImage, { StaticImageData } from 'next/image';

import { BreakpointAllNames } from 'Theme/types/breakpoints.type';

import { Container, OverflowWrapper, ImageWrapper, WaveSVG } from './Wave.styled';

export interface Position {
    top?: string | number;
    right?: string | number;
    bottom?: string | number;
    left?: string | number;
    transform?: string;
}

export interface Props {
    image?: StaticImageData & {
        position?: Partial<Record<BreakpointAllNames, Position>> | Position;
    };
    svg?: {
        wave: React.ElementType;
        position?: Partial<Record<BreakpointAllNames, Position>> | Position;
    };
    width: number;
    height: number;
    top?: string | number;
    mask?: CSS.Property.MaskImage;
    overflowHeight?: number;
}

const Wave: React.FC<Props> = props => {
    const { image, svg, width, height, top, mask, overflowHeight } = props;

    return (
        <Container>
            <OverflowWrapper $height={overflowHeight ? overflowHeight : height} $top={top}>
                {image && (
                    <ImageWrapper $position={image.position}>
                        <NextImage src={image.src} alt="" width={width} height={height} />
                    </ImageWrapper>
                )}
                {svg && (
                    <WaveSVG
                        as={svg.wave}
                        $position={svg.position}
                        $width={width}
                        $height={height}
                        $mask={mask}
                    />
                )}
            </OverflowWrapper>
        </Container>
    );
};

export default Wave;
