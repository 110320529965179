import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const KeyStatStyled = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto 15%;

        @media ${theme.breakpoints.large.media} {
            margin: auto;
        }

        @media ${theme.breakpoints.huge.media} {
            margin: auto 15%;
        }
    `}
`;

export const Circle = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 11rem;
        width: 11rem;

        @media ${theme.breakpoints.medium.media} {
            height: 14rem;
            width: 14rem;
            border: solid 0.2rem ${theme.colors.primary.hex};
            border-radius: 50%;
        }

        @media ${theme.breakpoints.large.media} {
            height: 18rem;
            width: 18rem;
        }

        @media ${theme.breakpoints.xlarge.media} {
            height: 21rem;
            width: 21rem;
        }
    `}
`;

export const Stat = styled(animated.span)`
    ${({ theme }) => css`
        color: ${theme.colors.primary.hex};
        border-radius: 50%;
        font-weight: 900;
        font-size: 2rem;
        margin-bottom: 0;

        @media ${theme.breakpoints.medium.media} {
            font-size: 2.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin-top: -1.5rem;
            font-size: 2.375rem;
        }
    `}
`;

export const Information = styled(animated.div)`
    ${({ theme }) => css`
        text-align: center;
        font-weight: light;
        font-size: 1rem;
        max-width: 12rem;

        @media ${theme.breakpoints.medium.media} {
            font-size: 1rem;
            margin-top: -1rem;
            max-width: 10rem;
        }

        @media ${theme.breakpoints.large.media} {
            max-width: 15rem;
            font-size: 1.25rem;
        }
    `}
`;

export const NumberContainer = styled(animated.span)`
    ${({ theme }) => css`
        font-size: 4rem;
        margin-bottom: 0;

        @media ${theme.breakpoints.medium.media} {
            font-size: 5rem;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 6.25rem;
        }
    `}
`;
