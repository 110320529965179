import styled, { css } from 'styled-components';
import NextImage from 'next/image';

import ClearingButton from 'Campaigns/Clearing/Button';

export const Container = styled.div`
    ${({ theme }) => css`
        background: ${theme.colors.campaigns.clearing.primary.hex};
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        color: ${theme.colors.campaigns.clearing.primaryContrast.hex};
        height: 100%;

        @media ${theme.breakpoints.large.media} {
            background: linear-gradient(
                to bottom,
                transparent 5rem,
                ${theme.colors.campaigns.clearing.primary.hex} 5rem,
                ${theme.colors.campaigns.clearing.primary.hex} 100%
            );
            flex-direction: column;
            padding: 0 1rem 1.5rem;
        }
    `}
`;

export const StyledImage = styled(NextImage)`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
            max-width: 100%;
            height: auto;
        }
    `}
`;

export const PreHeading = styled.span`
    ${({ theme }) => css`
        display: block;
        font-family: 'acumin-pro-extra-condensed', sans-serif;
        font-size: 1.2rem;
        line-height: 1;
        text-transform: uppercase;

        @media ${theme.breakpoints.large.media} {
            font-size: 2rem;
            text-align: center;
        }
    `}
`;

export const Heading = styled.h3`
    ${({ theme }) => css`
        margin: 0.25rem 0 0;
        font-size: 1.25rem;
        font-weight: 800;
        line-height: 1.3;

        @media ${theme.breakpoints.large.media} {
            font-size: 2.5rem;
            text-align: center;
        }
    `}
`;

export const StyledButton = styled(ClearingButton)`
    ${({ theme }) => css`
        padding: 0.75rem;
        margin: 0;
        font-weight: 400;
        font-size: 1rem;
        flex: 0 0 50%;

        @media ${theme.breakpoints.large.media} {
            padding: 1rem;
            font-size: 1.25rem;
            flex: 0 0 auto;
        }
    `}
`;
