import styled, { css } from 'styled-components';
import { Play as PlaySVG } from '@solent-university/solent-icons';

import Image from 'Elements/Image';

export const Container = styled.div`
    ${({ theme }) => css`
        --background-gradient-angle: 218deg;

        position: relative;
        max-width: 100%;
        background: linear-gradient(
            var(--background-gradient-angle),
            rgba(${theme.colors.secondary.tints[0].rgb}, 0) 0%,
            rgba(${theme.colors.secondary.tints[0].rgb}, 1) 55%,
            rgba(${theme.colors.secondary.tints[0].rgb}, 1) 100%
        );

        @media ${theme.breakpoints.medium.media} {
            --background-gradient-angle: 245deg;
        }

        @media ${theme.breakpoints.xlarge.media} {
            --background-gradient-angle: 254deg;
        }
    `}
`;

export const WaveWrapper = styled.div`
    ${({ theme }) => css`
        position: absolute;
        overflow: hidden;
        top: -9rem;
        bottom: 0;
        width: 100%;
        z-index: -1;

        @media ${theme.breakpoints.smallLarge.media} {
            top: -10.5rem;
        }

        @media ${theme.breakpoints.medium.media} {
            top: -15rem;
        }

        @media ${theme.breakpoints.large.media} {
            top: -20rem;
        }

        @media ${theme.breakpoints.xlarge.media} {
            top: -26rem;
        }
    `}
`;

export const Wave = styled(Image)`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        right: 65%;
        width: 30rem;
        object-position: bottom right;

        @media ${theme.breakpoints.smallLarge.media} {
            right: 75%;
            width: 35rem;
        }

        @media ${theme.breakpoints.medium.media} {
            right: 40%;
            width: 60rem;
        }

        @media ${theme.breakpoints.large.media} {
            width: 75rem;
        }

        @media ${theme.breakpoints.xlarge.media} {
            width: auto;
        }

        @media ${theme.breakpoints.colossal.media} {
            mask-image: linear-gradient(
                110deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) 10%,
                black 25%,
                black 100%
            );
        }
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        padding: 2rem 0;

        @media ${theme.breakpoints.medium.media} {
            padding: 2.75rem 0;
        }

        @media ${theme.breakpoints.xlarge.media} {
            padding: 4.5rem 0;
        }
    `}
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        margin: 0;
        width: 100%;
        color: ${theme.colors.primary.hex};

        @media ${theme.breakpoints.xlarge.media} {
            width: 40%;
        }
    `}
`;

export const Content = styled.div`
    ${({ theme }) => css`
        margin: 1rem 0;

        @media ${theme.breakpoints.xlarge.media} {
            margin: 2.25rem 0;
        }
    `}
`;

export const MobileMapOverflowWrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        height: 11.5rem;
        overflow: hidden;

        @media ${theme.breakpoints.smallLarge.media} {
            height: 17.5rem;
        }

        @media ${theme.breakpoints.medium.media} {
            display: none;
        }
    `}
`;

export const MapOverflowWrapper = styled.div`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        overflow: hidden;
        display: none;

        @media ${theme.breakpoints.medium.media} {
            display: block;
        }

        @media ${theme.breakpoints.xlarge.media} {
            position: relative;
            width: 100%;
            height: 100%;
        }
    `}
`;

export const MapContainer = styled.div`
    ${({ theme }) => css`
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;

        @media ${theme.breakpoints.medium.media} {
            width: 110%;
            bottom: 1.5rem;
        }

        @media ${theme.breakpoints.xlarge.media} {
            width: 100%;
        }
    `}
`;

export const Map = styled(Image)`
    position: relative;
    width: 100%;
    object-fit: contain;
    object-position: bottom left;
`;

export const VideoButton = styled.button`
    position: absolute;
    left: 61%;
    bottom: 22.5%;
    transform: translate(-50%, 50%);
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.interactive.hex};
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    font-weight: 700;
    font-size: 1.5rem;
    cursor: pointer;
`;

export const Play = styled.span`
    background: currentColor;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 200ms ease;

    ${VideoButton}:hover & {
        transform: scale(1.2);
    }
`;

export const PlayIcon = styled(PlaySVG)`
    margin-left: 0.125rem;
    width: 1.125rem;
    height: 1.125rem;
    color: ${({ theme }) => theme.colors.secondary.hex};
`;
