import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import { Play as PlaySVG } from '@solent-university/solent-icons';

import Link from 'Elements/Link';

export const Container = styled.figure`
    position: relative;
    margin: 0;
`;

export const Button = styled.button`
    ${({ theme }) => css`
        background: transparent;
        padding: 0;
        margin: 0;
        display: block;
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        cursor: pointer;
        border-radius: 0.5rem;
        overflow: hidden;
        border: 0.0625rem solid ${theme.colors.secondary.tints[1].hex};
        box-sizing: content-box;

        &:focus {
            outline: none;
            border-color: ${theme.colors.interactive.hex};
        }
    `}
`;

export const ImageWrapper = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const IconWrapper = styled(animated.span)`
    ${({ theme }) => css`
        position: absolute;
        background-color: ${theme.colors.primary.hex};
        width: 3rem;
        height: 3rem;
        top: calc(50% - 1.5rem);
        left: calc(50% - 1.5rem);
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        border-radius: 50%;

        @media ${theme.breakpoints.medium.media} {
            width: 6rem;
            height: 6rem;
            top: calc(50% - 3rem);
            left: calc(50% - 3rem);
        }
    `}
`;

export const PlayIcon = styled(PlaySVG)`
    ${({ theme }) => css`
        color: white;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.4rem;

        @media ${theme.breakpoints.medium.media} {
            width: 2.25rem;
            height: 2rem;
        }
    `}
`;

export const ResponsiveVideoWrapper = styled.div`
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
`;

export const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

export const FigureCaption = styled.figcaption`
    ${({ theme }) => css`
        font-size: 1.125rem;
        font-weight: 700;
        margin-top: 1.25rem;
        line-height: 1.2;

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.25rem;
            margin-top: 1.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 1.5rem;
        }
    `}
`;

export const StyledLink = styled(Link)`
    display: block;
    margin-top: 0.5rem;
    font-size: 1.1125rem;
`;
