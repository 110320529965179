import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const Container = styled.figure`
    position: relative;
    width: 100%;
    min-height: 20rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    padding-bottom: 4rem;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        padding-bottom: 3rem;
        min-height: 22;
    }

    @media ${({ theme }) => theme.breakpoints.xlarge.media} {
        min-height: 35rem;
    }
`;

export const Heading = styled.h1`
    ${({ theme }) => css`
        font-size: 2.5rem;
        position: relative;
        letter-spacing: 0;
        line-height: 1;
        color: var(--text-color, ${theme.colors.text.hex});
        margin-top: 4rem;

        @media ${theme.breakpoints.smallLarge.media} {
            font-size: 3.2rem;
        }

        @media ${theme.breakpoints.medium.media} {
            margin-top: 2.5rem;
            margin-bottom: 2rem;
            font-size: 5rem;
            line-height: 4.5rem;
        }

        @media ${theme.breakpoints.xlarge.media} {
            font-size: 6rem;
            line-height: 6.25rem;
            margin-top: 8.25rem;
        }
    `}
`;

export const SearchContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        position: relative;

        @media ${theme.breakpoints.medium.media} {
            margin: 0;
        }
    `}
`;

export const PostHeading = styled.span`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        display: block;
        font-weight: 300;
        line-height: 1.2;
        font-size: 1.5rem;
        padding-top: 2rem;

        @media ${theme.breakpoints.large.media} {
            padding-top: 0;
            margin-top: 2rem;
            font-size: 2.25rem;
        }
    `}
`;

export const CollossalImageWrapper = styled.div`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.colossal.media} {
            display: block;
        }
    `}
`;
export const DesktopImageWrapper = styled.div`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.mediumSmall.media} {
            display: block;
        }

        @media ${theme.breakpoints.colossal.media} {
            display: none;
        }
    `}
`;
export const MobileImageWrapper = styled.div`
    ${({ theme }) => css`
        display: block;

        @media ${theme.breakpoints.mediumSmall.media} {
            display: none;
        }
    `}
`;
