import styled, { css } from 'styled-components';

import ArrowButton from 'Elements/Button/Arrow';

export const StyledArrowButton = styled(ArrowButton)`
    ${({ theme }) => css`
        position: relative;
        /*added important to remove animation on hover*/
        border-radius: 0 !important;
        padding: 1rem 1.5rem;
        white-space: normal;

        @media ${theme.breakpoints.smallLarge.media} {
            padding: 1.25rem 3rem;
        }
    `}
`;
