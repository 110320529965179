import React from 'react';

import KeyStat, { Props as KeyStatProps } from 'Components/KeyStat/KeyStat';
import ButtonArrow, { Props as ButtonProps } from 'Elements/Button/Arrow';

import {
    KeyStatsStyled,
    StatsContainer,
    KeyStatsContainer,
    Heading,
    CtaContainer
} from './KeyStats.styled';

interface KeyStatsProps extends KeyStatProps {
    id: string;
}
export interface Props {
    className?: string;
    keyStats: KeyStatsProps[];
    heading: string;
    headingType?: 'h2' | 'h3';
    link: ButtonProps;
}

const KeyStats: React.FC<Props> = props => {
    const { heading, link, keyStats, className, headingType = 'h2' } = props;

    return (
        <KeyStatsStyled className={className}>
            <StatsContainer>
                {keyStats.map(({ id, ...props }) => (
                    <KeyStatsContainer key={id}>
                        <li>
                            <KeyStat {...props} />
                        </li>
                    </KeyStatsContainer>
                ))}
            </StatsContainer>
            <CtaContainer>
                <Heading as={headingType}>{heading}</Heading>
                <ButtonArrow {...link} />
            </CtaContainer>
        </KeyStatsStyled>
    );
};

export default KeyStats;
