import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import Link from 'Elements/Link';

export const Container = styled.article`
    ${({ theme }) => css`
        position: relative;
        height: 100%;
        min-height: 8.5rem;
        background: ${theme.colors.tertiary.hex};
        color: ${theme.colors.primaryContrast.hex};
        border-radius: 0.5rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media ${theme.breakpoints.large.media} {
            min-height: 10rem;
            padding: 1rem 1.5rem;
        }
    `}
`;

export const Expandable = styled(animated.div)`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${theme.colors.tertiary.hex};
        border-radius: 0.5rem;
        box-sizing: content-box;
        z-index: -1;
    `}
`;

export const Heading = styled.h3<{ $colour?: string }>`
    ${({ theme, $colour }) => css`
        font-family: ${theme.fonts.secondary};
        font-weight: 300;
        font-size: 1.125rem;
        line-height: 1.25;
        margin: 0.5rem 0 0;
        z-index: 1;

        ${$colour &&
        css`
            color: ${$colour};
        `}

        @media ${theme.breakpoints.large.media} {
            font-size: 1.5rem;
        }
    `}
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    font-size: inherit;
    color: inherit;

    &:hover,
    &:focus {
        text-decoration: underline;
        text-decoration-thickness: 0.075em;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const Details = styled(animated.div)`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 0.75rem;
        margin: 0.5rem 0 0;

        @media ${theme.breakpoints.large.media} {
            margin: 1.75rem 0 0;
        }
    `}
`;

export const Location = styled.p`
    ${({ theme }) => css`
        font-size: 0.875rem;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 0.35rem;

        @media ${theme.breakpoints.large.media} {
            font-size: 1rem;
        }
    `}
`;

export const StyledIcon = styled.svg<{ $colour?: string }>`
    ${({ theme, $colour }) => css`
        width: auto;
        height: 1rem;
        overflow: visible;

        ${$colour &&
        css`
            color: ${$colour};
        `}

        @media ${theme.breakpoints.large.media} {
            height: 1.15rem;
        }
    `}
`;

export const Date = styled.p`
    ${({ theme }) => css`
        font-size: 0.875rem;
        margin: 0;
        line-height: 1.2;
        padding: 0.15rem 0;

        @media ${theme.breakpoints.large.media} {
            font-size: 1rem;
        }
    `}
`;
