import React, { useState } from 'react';
import { format } from 'date-fns';
import { useTheme } from 'styled-components';
import { useSpring } from '@react-spring/web';
import { Globe, Pindrop } from '@solent-university/solent-icons';

import { LinkProps } from 'Elements/Link';
import Pill from 'Elements/Pill';

import {
    Container,
    Expandable,
    Heading,
    StyledLink,
    Details,
    Location,
    StyledIcon,
    Date
} from './Event.styled';

export interface Props {
    id: string;
    link: Omit<LinkProps, 'label'> & { label: string };
    date?: Date;
    location?: string;
    colour?: string;
    tabIndex?: number;
}

const Event: React.FC<Props> = props => {
    const { colors } = useTheme();
    const { id, link, date, location, colour = colors.accents[3].hex, tabIndex } = props;

    const [isHovered, setIsHovered] = useState(false);

    const expandHeight = '1rem';
    const config = {
        duration: 250
    };

    const expandSpring = useSpring({
        paddingBottom: isHovered ? expandHeight : '0',
        config
    });

    const shiftDetailsSpring = useSpring({
        top: isHovered ? `${expandHeight}` : '0',
        config
    });

    return (
        <Container
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocus={() => setIsHovered(true)}
            onBlur={() => setIsHovered(false)}
        >
            <Expandable style={expandSpring} />
            <Heading $colour={colour}>
                <StyledLink
                    {...link}
                    ariaLabel={`Read more on ${link.label}`}
                    id={id}
                    tabIndex={tabIndex}
                    data-hovered={isHovered}
                />
            </Heading>
            <Details style={shiftDetailsSpring}>
                {date && (
                    <Pill
                        backgroundColor={colors.accents[25].hex}
                        textColor={colors.primaryContrast.hex}
                    >
                        <Date>{format(date, 'E d LLLL')}</Date>
                    </Pill>
                )}
                {location ? (
                    <Location>
                        <StyledIcon
                            as={location === 'Virtual' ? Globe : Pindrop}
                            $colour={colour}
                        />
                        {location}
                    </Location>
                ) : (
                    <></>
                )}
            </Details>
        </Container>
    );
};

export default Event;
