import React, { useEffect, useState } from 'react';

import type { Props as InputSearchProps } from '../Search';
import Link from 'Elements/Link';

import { Container, InputSearch, AutoComplete, Result } from './Course.styled';

export type ResultsDisplay = 'dropdown' | 'list';

export interface Props extends InputSearchProps {
    fetchResults: (searchTerm: string) => {
        id: string;
        label: string;
        href: string;
    }[];
    resultsDisplayType?: ResultsDisplay;
}

const Course: React.FC<Props> = props => {
    const {
        placeholder,
        search,
        label,
        fetchResults,
        resultsDisplayType = 'dropdown',
        className,
        ...rest
    } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState<{ id: string; label: string; href: string }[]>([]);
    const [inputFocused, setInputFocused] = useState(false);

    const handleBlur = (event: React.FocusEvent) => {
        if (
            event.relatedTarget === null ||
            !event.currentTarget.contains(event.relatedTarget as Node)
        ) {
            setInputFocused(false);
        }
    };

    useEffect(() => {
        // Implement useFetch hook
        const results = fetchResults(searchTerm.toLowerCase());

        setResults(results);
    }, [searchTerm]);

    // TODO check that onBlur isnt called on link focus, onFocus and blur uses focus in focusout in react 17
    return (
        <Container
            onFocus={() => setInputFocused(true)}
            onBlur={e => resultsDisplayType !== 'list' && handleBlur(e)}
            className={className}
        >
            <InputSearch
                placeholder={placeholder}
                search={search}
                label={label}
                autoComplete="off"
                handleValueChange={(value: string) => setSearchTerm(value)}
                className={className}
                showIcon={true}
                {...rest}
            />
            {results.length > 0 && inputFocused && (
                <AutoComplete $displayType={resultsDisplayType}>
                    {results.map(result => (
                        <Result key={result.id}>
                            <Link
                                tabIndex={0}
                                href={result.href}
                                className="c-search-course-submit"
                            >
                                {result.label}
                            </Link>
                        </Result>
                    ))}
                </AutoComplete>
            )}
        </Container>
    );
};

export default Course;
