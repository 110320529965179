import React, { useState } from 'react';

import { StaticImageData } from 'next/image';

import ArrowLink, { ArrowLinkProps } from 'Elements/Link/Arrow';

import Video, { VideoProps } from 'Components/Video';
import Grid, { Col } from 'Components/Grid';
import Modal from 'Components/Modal';

import wavePNG from 'Public/images/footer-visit/wave.png';
import ukMapPNG from 'Public/images/footer-visit/uk-map.png';

import {
    Container,
    WaveWrapper,
    Wave,
    Inner,
    Heading,
    Content,
    MobileMapOverflowWrapper,
    MapOverflowWrapper,
    MapContainer,
    Map,
    VideoButton,
    Play,
    PlayIcon
} from './FooterVisit.styled';

export interface Props {
    heading: string;
    children?: React.ReactNode;
    link: ArrowLinkProps;
    videoButton: {
        label: string;
        ariaLabel: string;
    };
    //overwriting img src to make conditional & render button based on whether src provided (request due to publishing issue w/cms)
    video: VideoProps & { image: { src?: StaticImageData | string } };
}

const FooterVisit: React.FC<Props> = props => {
    const { heading, children, link, videoButton, video } = props;
    const [isModalVisible, setModalVisible] = useState(false);

    const VideoButtonComponent = () => (
        <VideoButton aria-label={videoButton.ariaLabel} onClick={() => setModalVisible(true)}>
            <Play>
                <PlayIcon />
            </Play>
            {videoButton.label}
        </VideoButton>
    );

    return (
        <Container>
            <WaveWrapper>
                <Wave src={wavePNG} alt="" loading="lazy" />
            </WaveWrapper>
            <Grid position="static">
                <Col>
                    <MobileMapOverflowWrapper>
                        <MapContainer>
                            <Map src={ukMapPNG} alt="" loading="lazy" />
                            <VideoButtonComponent />
                        </MapContainer>
                    </MobileMapOverflowWrapper>
                </Col>
                <Col m={6}>
                    <Inner>
                        <Heading>{heading}</Heading>
                        <Content>{children}</Content>
                        <ArrowLink {...link} />
                    </Inner>
                </Col>
                <Col m={6}>
                    <MapOverflowWrapper>
                        <MapContainer>
                            <Map src={ukMapPNG} alt="" loading="lazy" />
                            {video.image.src && <VideoButtonComponent />}
                        </MapContainer>
                    </MapOverflowWrapper>
                </Col>
            </Grid>
            <Modal
                id="m-footer-visit-video"
                heading={video.title}
                isVisible={isModalVisible}
                closeFnc={() => setModalVisible(false)}
                customWidth="70rem"
            >
                {video.image.src && (
                    <Video
                        {...video}
                        image={{
                            src: video.image.src,
                            alt: video.image.alt,
                            priority: true
                        }}
                    />
                )}
            </Modal>
        </Container>
    );
};

export default FooterVisit;
