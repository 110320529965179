import styled, { css } from 'styled-components';

import { BreakpointAllNames } from 'Theme/types/breakpoints.type';
import type { Props, Position } from './Wave';

export const Container = styled.div`
    position: relative;
`;

export const OverflowWrapper = styled.div<{
    $height?: Props['height'];
    $top?: Props['top'];
}>`
    ${({ $height, $top }) => css`
        position: absolute;
        top: ${$top || 0};
        right: 0;
        left: 0;
        overflow: hidden;
        height: ${typeof $height === 'number' ? $height + 'px' : '100vh'};
        z-index: -1;
    `}
`;

export const ImageWrapper = styled.div<{
    $position?: Partial<Record<BreakpointAllNames, Position>> | Position;
}>`
    ${({ theme, $position }) => css`
        position: absolute;

        ${typeof $position == 'object' &&
        Object.keys($position).map(key => {
            const breakpoint = Object.keys(theme.breakpoints).find(
                bpKey => bpKey === key || theme.breakpoints[bpKey].shortName === key
            );

            if (breakpoint) {
                return css`
                    @media ${theme.breakpoints[breakpoint].media} {
                        top: ${$position[key].top};
                        left: ${$position[key].left};
                        bottom: ${$position[key].bottom};
                        right: ${$position[key].right};
                        transform: ${$position[key].transform};
                    }
                `;
            } else {
                return css`
                    top: ${'top' in $position && $position.top};
                    left: ${'left' in $position && $position.left};
                    bottom: ${'bottom' in $position && $position.bottom};
                    right: ${'right' in $position && $position.right};
                    transform: ${'transform' in $position && $position[key].transform};
                `;
            }
        })}
    `}
`;

export const WaveSVG = styled.svg<{
    $position?: Partial<Record<BreakpointAllNames, Position>> | Position;
    $width: Props['width'];
    $height: Props['height'];
    $mask: Props['mask'];
}>`
    ${({ theme, $position, $width, $height, $mask }) => css`
        position: absolute;
        width: ${$width}px;
        height: ${$height}px;
        mask-image: ${$mask};

        ${typeof $position == 'object' &&
        Object.keys($position).map(key => {
            const breakpoint = Object.keys(theme.breakpoints).find(
                bpKey => bpKey === key || theme.breakpoints[bpKey].shortName === key
            );

            if (breakpoint) {
                return css`
                    @media ${theme.breakpoints[breakpoint].media} {
                        top: ${$position[key].top};
                        left: ${$position[key].left};
                        bottom: ${$position[key].bottom};
                        right: ${$position[key].right};
                        transform: ${$position[key].transform};
                    }
                `;
            } else {
                return css`
                    top: ${'top' in $position && $position.top};
                    left: ${'left' in $position && $position.left};
                    bottom: ${'bottom' in $position && $position.bottom};
                    right: ${'right' in $position && $position.right};
                    transform: ${'transform' in $position && $position[key].transform};
                `;
            }
        })}
    `}
`;
