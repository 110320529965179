import React, { useEffect, useState } from 'react';
import NextImage, { StaticImageData } from 'next/image';

import type { Props as SearchCourseProps } from 'Components/Search/Course';
import { ButtonVariants } from 'Theme/types/elements.type';

import ArrowButton from 'Elements/Button/Arrow';
import { LinkButtonProps } from 'Elements/Button';

import SearchCourse from 'Components/Search/Course';
import Grid, { Col } from 'Components/Grid';

import {
    Wrapper,
    Heading,
    Container,
    SearchContainer,
    PostHeading,
    DesktopImageWrapper,
    MobileImageWrapper,
    CollossalImageWrapper
} from './Homepage.styled';

export interface Props {
    imageThemeBuilder: {
        textColor?: React.CSSProperties['color'];
        searchColor?: React.CSSProperties['color'];
        buttonVariant?: ButtonVariants;
        desktop: {
            src: StaticImageData | string;
            alt: string | undefined;
        };
        mobile: {
            src: StaticImageData | string;
            alt: string | undefined;
        };
    }[];
    heading: string;
    postHeading?: string;
    button?: LinkButtonProps;
    useCourseSearch: boolean;
    searchCourse?: SearchCourseProps;
}

const Homepage: React.FC<Props> = props => {
    const { imageThemeBuilder, heading, searchCourse, postHeading, useCourseSearch } = props;
    const [imageIndex, setImageIndex] = useState(0);
    const [isLoaded, setIsloaded] = useState(false);

    useEffect(() => {
        //get random image on load
        setImageIndex(Math.floor(Math.random() * props.imageThemeBuilder.length));
        setIsloaded(true);
    }, []);

    return (
        <Wrapper
            style={
                {
                    '--text-color': imageThemeBuilder[imageIndex].textColor
                } as React.CSSProperties
            }
        >
            <Container>
                {isLoaded && (
                    <React.Fragment>
                        <CollossalImageWrapper>
                            <NextImage
                                src={imageThemeBuilder[imageIndex].desktop.src}
                                alt={imageThemeBuilder[imageIndex].desktop.alt || ''}
                                fill
                                style={{ objectFit: 'cover', objectPosition: '50% 15%' }}
                                quality={100}
                                priority
                            />
                        </CollossalImageWrapper>
                        <DesktopImageWrapper>
                            <NextImage
                                src={imageThemeBuilder[imageIndex].desktop.src}
                                alt={imageThemeBuilder[imageIndex].desktop.alt || ''}
                                fill
                                style={{ objectFit: 'cover', objectPosition: '50% 50%' }}
                                quality={100}
                                priority
                            />
                        </DesktopImageWrapper>
                        <MobileImageWrapper>
                            <NextImage
                                src={imageThemeBuilder[imageIndex].mobile.src}
                                alt={imageThemeBuilder[imageIndex].mobile.alt || ''}
                                fill
                                style={{ objectFit: 'cover', objectPosition: '60% 50%' }}
                                quality={100}
                                priority
                            />
                        </MobileImageWrapper>
                    </React.Fragment>
                )}
                <Grid>
                    <Col b={9} s={8} sl={7} m={6}>
                        <Heading aria-label={`${heading} ` + `${postHeading}`}>
                            {heading}
                            {postHeading && <PostHeading>{postHeading}</PostHeading>}
                        </Heading>
                    </Col>

                    <Col sl={9} m={[1, 7]} l={[1, 6]}>
                        {useCourseSearch && searchCourse && isLoaded ? (
                            <SearchContainer>
                                <SearchCourse
                                    {...searchCourse}
                                    themeColor={imageThemeBuilder[imageIndex].searchColor}
                                    heightType="tall"
                                />
                            </SearchContainer>
                        ) : (
                            isLoaded && (
                                <ArrowButton
                                    {...props.button}
                                    variant={
                                        imageThemeBuilder[imageIndex].buttonVariant &&
                                        imageThemeBuilder[imageIndex].buttonVariant
                                    }
                                />
                            )
                        )}
                    </Col>
                </Grid>
            </Container>
        </Wrapper>
    );
};

export default Homepage;
