import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $showBorder: boolean }>`
    ${({ theme, $showBorder }) => css`
        display: inline-block;
        border-radius: 1.5rem;
        color: var(--text-color, ${theme.colors.text.hex});
        background-color: var(--bg-color, ${theme.colors.secondary.tints[10].hex});
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.1rem 1rem;
        transform: translateZ(0);

        ${$showBorder &&
        css`
            border: ${theme.commonStyles.brandPixel} solid ${theme.colors.tertiary.hex};
        `}
    `}
`;
