import React from 'react';
import cmsParser from 'Common/util/cmsParser';
import setCacheHeaders from 'Common/func/setCacheHeaders';
import contensis from 'Common/api/contensis';
import { Op, OrderBy } from 'contensis-delivery-api';
import { excludeMetadata } from 'Common/util/contensis/api';

import type { NextPageWithLayout, GetServerSideProps } from 'next';

import MainLayout from 'Layout/Main';
import Metadata from 'Components/Metadata';
import Template, { datamap, PageData } from 'Templates/Homepage';

const Home: NextPageWithLayout<PageData> = ({ entry, news, events, courses }) => (
    <>
        <Metadata {...cmsParser.metadata(entry)} />
        <Template {...datamap({ entry, news, events, courses })} />
    </>
);

Home.getLayout = (page, props) => {
    return (
        <>
            <MainLayout {...props} logo="red">
                {page}
            </MainLayout>
        </>
    );
};

export const getServerSideProps: GetServerSideProps<any> = async ({ preview, res }) => {
    const { fetch, surrogateKeys } = contensis({ preview, fetchSurrogateKeys: true });
    const { data, error } = await fetch(async (client, search) => {
        const resPage = await client.nodes.getRoot({
            entryLinkDepth: 2,
            entryFields: ['*', ...excludeMetadata]
        });

        if (!resPage.entry) {
            return { error: true };
        }

        const linkDepth = 3;
        const featuredNewsAndBlogsQuery = [
            Op.in('sys.contentTypeId', 'newsArticle', 'blogArticle'),
            Op.equalTo('miscellaneousTags', '0/1259/1262/1389/1623'),
            Op.exists('customTags', false),
            Op.or(Op.exists('isIndependentBlog', false), Op.equalTo('isIndependentBlog', false)),
            Op.exists('sys.slug', true)
        ];

        const eventsQuery = [
            Op.equalTo('sys.contentTypeId', 'event'),
            Op.greaterThan('eventDate.from', new Date().toISOString()),
            Op.exists('sys.slug', true)
        ];

        const coursesQuery = [
            Op.or(
                Op.equalTo('sys.contentTypeId', 'courseUndergraduate'),
                Op.equalTo('sys.contentTypeId', 'coursePostgraduate'),
                Op.equalTo('sys.contentTypeId', 'courseApprenticeships'),
                Op.equalTo('sys.contentTypeId', 'courseProfessional'),
                Op.equalTo('sys.contentTypeId', 'courseFoundationYears')
            ),
            Op.exists('sys.uri', true)
        ];

        const [resFeaturedNewsAndBlogs, resEvents, resCourses] = await Promise.all([
            search(featuredNewsAndBlogsQuery, {
                linkDepth,
                orderBy: OrderBy.desc('publishDate'),
                pageSize: 4,
                fields: [
                    'sys.id',
                    'sys.slug',
                    'heroImage',
                    'title',
                    'description',
                    'publishDate',
                    'subejctArea',
                    'storyClassification'
                ]
            }),
            search(eventsQuery, {
                linkDepth,
                orderBy: OrderBy.asc('eventDate.from'),
                pageSize: 4,
                fields: [
                    'sys.id',
                    'sys.slug',
                    'title',
                    'eventDate.from',
                    'basicLocation',
                    'subjectArea',
                    'storyClassification'
                ]
            }),
            search(coursesQuery, {
                linkDepth,
                orderBy: OrderBy.asc('name'),
                pageSize: 400,
                fields: ['sys.id', 'sys.uri', 'name', 'degreeType']
            })
        ]);

        return {
            entry: resPage.entry,
            news: resFeaturedNewsAndBlogs.items,
            events: resEvents.items,
            courses: resCourses.items
        };
    });

    if (error) {
        return { notFound: true };
    } else {
        setCacheHeaders(res, surrogateKeys);

        return { props: data };
    }
};

export default Home;
