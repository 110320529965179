import React from 'react';
import { useTheme } from 'styled-components';

import {
    type CardProps,
    isBENCard,
    isVideoCard,
    isEventCard,
    isListingCard,
    isClearingCard
} from 'Common/util/ben';

import Section from 'Elements/Section';
import { LinkProps } from 'Elements/Link';

import Grid, { Col } from 'Components/Grid';
import BENCard from 'Components/Card/Ben';
import VideoCard from 'Components/Card/Ben/Video';
import EventCard from 'Components/Card/Ben/Event';
import ListingCard from 'Components/Card/Listing';
import ClearingCard from 'Campaigns/Clearing/Card';

import { Top, Heading, StyledLink, Listing, ListItem } from './Ben.styled';

export interface Props {
    id: string;
    heading: string;
    moreLink?: LinkProps;
    items: CardProps[];
    paddingTop?: boolean;
    showBackground?: boolean;
}

const Ben: React.FC<Props> = props => {
    const { id, heading, moreLink, items, paddingTop = false, showBackground = true } = props;

    const theme = useTheme();
    const colours = [
        theme.colors.accents[4].hex,
        theme.colors.accents[3].hex,
        theme.colors.accents[6].hex,
        theme.colors.accents[8].hex
    ];

    return (
        <Section id={id} background={showBackground} paddingTop={paddingTop ? 1 : undefined}>
            <Grid>
                <Col>
                    <Top>
                        <Heading>{heading}</Heading>
                        {moreLink && <StyledLink {...moreLink} />}
                    </Top>
                    <Listing>
                        {items.map((item, index) => (
                            <ListItem key={index}>
                                {isBENCard(item) && <BENCard {...item} />}
                                {isVideoCard(item) && <VideoCard {...item} />}
                                {isEventCard(item) && (
                                    <EventCard {...item} colour={colours[index % colours.length]} />
                                )}
                                {isListingCard(item) && <ListingCard {...item} />}
                                {isClearingCard(item) && <ClearingCard {...item} />}
                            </ListItem>
                        ))}
                    </Listing>
                </Col>
            </Grid>
        </Section>
    );
};

export default Ben;
