import React from 'react';

import { ArrowButtonProps } from 'Elements/Button/Arrow';

import { StyledArrowButton } from './Button.styled';

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = ArrowButtonProps;

const Button: React.FC<Props> = props => {
    return <StyledArrowButton {...props} />;
};

export default Button;
