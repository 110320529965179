import React from 'react';
import { useInView } from 'react-intersection-observer';
import Mustache from 'mustache';
import { useSpring } from '@react-spring/web';
import parse from 'html-react-parser';

import { KeyStatStyled, Circle, Stat, Information, NumberContainer } from './KeyStat.styled';

export interface Props {
    statValue: number;
    stat: string;
    description: string;
}

const KeyStat: React.FC<Props> = props => {
    const { statValue, stat, description } = props;
    const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });

    /* Generated string using Mustache */
    const output = Mustache.render(stat, { number: '<number></number>' });

    /*  Animations */
    const animateNumber = useSpring({
        val: inView ? statValue : 0,
        opacity: inView ? 1 : 0,
        delay: 500,
        from: { val: 0 }
    });

    const animateState = useSpring({
        opacity: inView ? 1 : 0,
        config: { duration: 1000 }
    });

    const textStyle = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0px)' : 'translateY(50px)',
        delay: 500
    });

    return (
        <KeyStatStyled>
            <Circle>
                <Stat ref={ref} style={animateState}>
                    {parse(output, {
                        replace: ({ name }) =>
                            name === 'number' && (
                                <NumberContainer>
                                    {animateNumber.val.to(val => Math.floor(val))}
                                </NumberContainer>
                            )
                    })}
                </Stat>
                <Information style={textStyle}>{description}</Information>
            </Circle>
        </KeyStatStyled>
    );
};

export default KeyStat;
